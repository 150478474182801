import React from 'react';

export default class Info extends React.Component <any,any>{
    constructor(props: any) {
        super(props);
        this.state = {
          categories: [],
          brands: {},
        };
      }
    render() {

        console.log(this?.props?.dataSetup,'ccc')
        return (
            <div className="contact-info">
                <p className="mb-4"><strong>VỀ CHÚNG TÔI</strong></p>
                <p>{this?.props?.dataSetup?.brand_name}</p>
                <p>Địa chỉ: S2.08 Vinhomes Ocean Park, Đa Tốn, Gia Lâm, Hà Nội</p>
                {/* <p>Chi nhánh TP HCM: 103 Phan Anh, phường Bình Trị Đông, quận Bình Tân, TP Hồ Chí Minh</p>
                <p>Chi nhánh Đà Nẵng: 145 B Cách Mạng Tháng 8, phường Khuê Trung, quận Cẩm Lệ, TP Đà Nẵng</p>
                <p>Chi nhánh Hưng Yên: thôn Tổ Hoả, xã Lý Thường Kiệt, huyện Yên Mỹ, tỉnh Hưng Yên</p> */}
                <p>Hotline: {this?.props?.dataSetup?.contact_phone}</p>
            </div>
        );
    }
}
