import React, { lazy } from "react";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import ServerRendingPage from "../pages/ServerRending";
const Homepage = lazy(() => import("../pages/Homepage"));
const WarrantyPage = lazy(() => import("../pages/WarrantyPage"));
const ProductDetailPage = lazy(() => import("../pages/Product"));
const ProductsPage = lazy(() => import("../pages/Products"));
const NotFoundPage = lazy(() => import("../components/errors/not-found"));
const LoginPage = lazy(() => import("../pages/LoginPage"));
const CartPage = lazy(() => import("../pages/Cart"));
const CheckoutPage = lazy(() => import("../pages/Checkout"));
const AccountPage = lazy(() => import("../pages/AccountPage"));
const OrderList = lazy(() => import("../pages/OrderList"));
const AuditPage = lazy(() => import("../pages/AuditPage"));
const Order = lazy(() => import("../pages/Order"));
const AddressPage = lazy(() => import("../pages/AddressPage"));
const RequestWarranty = lazy(() => import("../pages/RequestWarranty"));
const RequestWarrantyList = lazy(() => import("../pages/WarrantyRequests"));
const ProductCategoryPage = lazy(() => import("../pages/ProductCategory"));
const Vouchers = lazy(() => import("../pages/VouchersPage"));
const SubAccountPage = lazy(() => import("../pages/SubAccountPage"));
const SeriPage = lazy(() => import("../pages/SeriPage"));
const TransferOrderPage = lazy(() => import("../pages/TransferOrderPage"));
const RevenuePage = lazy(() => import("../pages/RevenuePage"));
const LuckyDrawPage = lazy(() => import("../pages/LuckyDrawPage"));

export const routeTitles: { [key: string]: string } = {
    "lich-su-bao-hanh": "Lịch sử bảo hành",
    "so-dia-chi": "Sổ địa chỉ",
    "tai-khoan": "Tài khoản",
    "quan-ly-don-hang": "Quản lý đơn hàng",
    "lich-su-thay-doi-so-du": "Lịch sử thay đổi số dư/công nợ",
    "don-hang": "Đơn hàng",
    "thanh-toan": "Thanh toán",
    "gio-hang": "Giỏ hàng",
    "dang-nhap": "Đăng nhập",
    "kick-hoat-bao-hanh": "Kích hoạt bảo hành",
    "yeu-cau-bao-hanh": "Yêu cầu bảo hành",
    bh: "Bảo hành",
    "san-pham": "Sản phẩm",
    "danh-muc": "Danh mục",
    "ma-khuyen-mai": "Mã khuyến mại",
    "tai-khoan-con": "Tài khoản con",
    "quan-ly-seri": "Quản lý seri",
    "quan-ly-xuat-nhap": "Đơn hàng tài khoản con",
    "thong-ke": "Thống kê",
};

export const routes = [
    {
        path: "/",
        component: Homepage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/lich-su-bao-hanh",
        component: RequestWarrantyList,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/so-dia-chi",
        component: AddressPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/tai-khoan",
        component: AccountPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/quan-ly-don-hang",
        component: OrderList,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/lich-su-thay-doi-so-du",
        component: AuditPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/don-hang/:type/:id",
        component: Order,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/thanh-toan",
        component: CheckoutPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/gio-hang",
        component: CartPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/dang-nhap",
        component: LoginPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/kick-hoat-bao-hanh/:id/:seri",
        component: WarrantyPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/kick-hoat-bao-hanh",
        component: WarrantyPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/yeu-cau-bao-hanh/:seri",
        component: RequestWarranty,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/bh/:seri",
        component: WarrantyPage,
        exact: true,
        type: PublicRoute,
    },
    {
        path: "/san-pham/:sku",
        component: ProductDetailPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/san-pham",
        component: ProductsPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/danh-muc/:slug",
        component: ProductCategoryPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/s/:slug",
        component: ServerRendingPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/ma-khuyen-mai",
        component: Vouchers,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/tai-khoan-con",
        component: SubAccountPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/quan-ly-seri",
        component: SeriPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/quan-ly-xuat-nhap",
        component: TransferOrderPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/thong-ke",
        component: RevenuePage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "/lucky-draw",
        component: LuckyDrawPage,
        exact: true,
        type: PrivateRoute,
    },
    {
        path: "*",
        component: NotFoundPage,
        exact: true,
        type: PublicRoute,
    },
];

